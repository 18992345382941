<template>
  <Container :vertical-padding="false">
    <Headline :size="1">{{ header }}</Headline>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  export default {
    mixins: [AlchemyElement],
    computed: {
      header() {
        return this.getValue("header")
      },
    },
  }
</script>
